<template>
    <div class="price-block">
        <div class="price-block__save-info-cover">
            <div class="price-block__save-info" v-if="showOldPriceAndDiscount">
                сэкономьте <span class="saved-value">
                {{numberWithSpaces(savingsValue)}} руб
            </span>
            </div>
        </div>

        <div class="price-block__text">
            <div class="price-block__icon"></div>
            <div class="price-block__text-value-cover">
                <div class="price-block__old-value" v-if="showOldPriceAndDiscount">
                    {{numberWithSpaces(landingPaymentFullPrice)}} руб
                </div>
                <div class="price-block__value">
                    {{numberWithSpaces(landingPaymentPriceToPay)}} руб
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'PriceBlock',
  props: {
    fullPackage: Boolean,
  },
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    landingPaymentFullPrice() {
      return this.$store.state.payment.landingPaymentFullPrice;
    },
    landingPaymentPriceToPay() {
      let priceToPay = this.$store.state.payment.landingPaymentPriceToPay;

      if (this.promoDiscountType === 'finalParice') {
        priceToPay = this.promoDiscount;
        this.$store.commit('setPromoDiscountValue', priceToPay - this.promoDiscount);
      }

      this.$store.commit('setPriceToPay', priceToPay);
      return priceToPay;
    },
    showOldPriceAndDiscount() {
      return this.landingPaymentFullPrice > this.landingPaymentPriceToPay;
    },
    savingsValue() {
      return this.landingPaymentFullPrice - this.landingPaymentPriceToPay;
    },
    promoDiscount() {
      return this.$store.state.payment.promoDiscount;
    },
    promoDiscountType() {
      return this.$store.state.payment.promoDiscountType;
    },
  },
  watch: {
    promoDiscountType() {
      this.checkIsPromoEnabled();
    },
  },
  methods: {
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    checkIsPromoEnabled() {
      if (this.promoDiscountType === 'publicWebinarSale') {
        const priceWithDiscount = this.landingPaymentPriceToPay - this.promoDiscount;

        /* Персчитаем стоимость вебинара */
        this.$store.commit('setLandingPaymentPriceToPay', priceWithDiscount);
        this.$store.commit('setPriceToPay', priceWithDiscount);
      }
    },
  },
  beforeDestroy() {
    this.$store.commit('setPromoDiscountType', '');
  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.price-block {
    margin: 24px 0 0;
    background: $white;
    border: solid $main-blue 1px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 67px;
    padding: 0 20px;

    &__icon {
        background: url('/images/check-icon-green.svg') no-repeat;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        position: relative;
        top: -1px
    }

    &__text {
        display: flex;
        align-items: center;
    }

    &__save-info-cover {
        display: flex;
        justify-content: center;
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
    }

    &__save-info {
        background: red;
        height: 20px;
        line-height: 20px;
        color: $white;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        border-radius: 10px;
        padding: 0 24px;

        /*iphone 5*/
        @media only screen and (max-width: 374px) {
            font-weight: 600;
            font-size: 9px;
            padding: 0 5px;
        }

        .saved-value {
            margin-left: 5px;
        }
    }

    &__discount-title {
        text-transform: uppercase;
        font-size: 12px;
        color: $darkGray;
        margin-bottom: 5px;
    }

    &__old-value {
        text-transform: uppercase;
        text-decoration: line-through;
        font-size: 14px;
        color: $darkGray;
        margin-bottom: 5px;
    }

    &__value {
        font-weight: 600;
        font-size: 18px;
    }

    &__discount-value {
        font-weight: 600;
        font-size: 18px;
    }
}
</style>
