<template>
    <div class="price-block" :class="{'space-between': showDiscount}">
        <div class="price-block__save-info-cover">
            <div class="price-block__save-info" v-if="isFullPackageSelected && getSavings > 0">
                сэкономьте <span class="saved-value">{{getSavings}} руб</span>
                <template v-if="promoDiscount && !localPromoError"> С УЧЕТОМ ПРОМОКОДА</template>
            </div>
            <div class="price-block__save-info"
                 v-if="promoDiscountValue > 0 && !isFullPackageSelected"
            >
                СЭКОНОМЬТЕ {{promoDiscountValue}} РУБ С УЧЕТОМ ПРОМОКОДА
            </div>
        </div>

        <div class="price-block__text">
            <div class="price-block__icon"></div>
            <div class="price-block__text-value-cover">
                <div class="price-block__old-value" v-if="showDiscount && getOldPrice !== getPrice">
                    {{getOldPrice}} руб
                </div>
                <div class="price-block__value">{{getPrice}} руб</div>
            </div>
        </div>

        <div class="price-block__discount" v-if="showDiscount && groupInfo.saleForAll > 0">
            <div class="price-block__discount-title">Скидка</div>
            <div class="price-block__discount-value">{{groupInfo.saleForAll}}%</div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'PriceBlock',
  props: {
    fullPackage: Boolean,
  },
  components: {},
  data() {
    return {
      complete: false,
    };
  },
  computed: {
    getOldPrice() {
      return this.isFullPackageSelected
        ? this.preparedInfo.priceForNoPayed : this.meditationInfo.discrPrice;
    },
    minTotalForPromo() {
      return this.$store.state.payment.minTotalForPromo;
    },
    showDiscount() {
      return this.isFullPackageSelected || this.isCurrentPackagePayed;
    },
    promoDiscount() {
      return this.$store.state.payment.promoDiscount;
    },
    promoDiscountValue() {
      return this.$store.state.payment.promoDiscountValue;
    },
    getPrice() {
      let price = 0;
      const discount = this.groupInfo.saleForAll / 100;

      if (this.isFullPackageSelected) {
        const fullPrice = this.preparedInfo.priceForNoPayed;

        price = fullPrice - (fullPrice * discount);
      } else if (this.isCurrentPackagePayed) {
        price = this.meditationInfo.discrPrice;
        price -= (price * discount);
      } else {
        price = this.meditationInfo.discrPrice;
      }

      let roundedPrice = Math.round(price);
      this.$store.commit('setPriceWithoutDiscount', roundedPrice);

      if (
        this.isFullPackageSelected
        && roundedPrice >= this.minTotalForPromo
        && this.promoDiscount > 1
      ) {
        roundedPrice -= this.promoDiscount;
      }

      if (roundedPrice >= this.minTotalForPromo && this.promoDiscount < 1) {
        const discountValue = Math.round(roundedPrice * this.promoDiscount);
        roundedPrice -= discountValue;
        this.$store.commit('setPromoDiscountValue', discountValue);
      }

      this.$store.commit('setPriceToPay', roundedPrice);

      return roundedPrice;
    },
    getSavings() {
      return this.preparedInfo.priceForNoPayed - this.getPrice;
    },
    preparedInfo() {
      return this.$store.getters.getMeditationsInfo;
    },
    isCurrentPackagePayed() {
      return this.$store.getters.isCurrentPackagePayed;
    },
    groupInfo() {
      return this.$store.state.meditations.currentMeditationGroup;
    },
    meditationInfo() {
      return this.$store.state.meditations.currentMeditation;
    },
    isFullPackageSelected() {
      return this.$store.state.payment.isFullPackageSelected;
    },
    localPromoError() {
      return this.$store.state.payment.localPromoError;
    },
  },
  methods: {

  },
};
</script>

<style scoped lang="scss">
 @import "../../assets/scss/common/variables";
 @import "../../assets/scss/common/forms";

.price-block {
    margin: 40px 0 0;
    background: $white;
    border: solid $main-blue 1px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 67px;
    padding: 0 20px;

    &.space-between {
        justify-content: space-between;
        padding-top: 5px;
    }

    &__icon {
        background: url('/images/check-icon-green.svg') no-repeat;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        position: relative;
        top: -1px
    }

    &__text {
        display: flex;
        align-items: center;
    }

    &__save-info-cover {
        display: flex;
        justify-content: center;
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
    }

    &__save-info {
        background: red;
        height: 20px;
        line-height: 20px;
        color: $white;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
        border-radius: 10px;
        padding: 0 24px;

        /*iphone 5*/
        @media only screen and (max-width: 374px) {
            font-weight: 600;
            font-size: 9px;
            padding: 0 5px;
        }

        .saved-value {
            margin-left: 5px;
        }
    }

    &__discount-title {
        text-transform: uppercase;
        font-size: 12px;
        color: $darkGray;
        margin-bottom: 5px;
    }

    &__old-value {
        text-transform: uppercase;
        text-decoration: line-through;
        font-size: 14px;
        color: $darkGray;
        margin-bottom: 5px;
    }

    &__value {
        font-weight: 600;
        font-size: 18px;
    }

    &__discount-value {
        font-weight: 600;
        font-size: 18px;
    }
}
</style>
