<template>
  <div class="ya-form" :class="{'ya-form--visible': isVisible}">
    <div class="ya-form__back" @click="closeYaPay"></div>

    <div class="ya-form__loading" v-if="loading">
      <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
                <rect x="20" y="50" width="4" height="10" fill="#000">
                  <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="30" y="50" width="4" height="10" fill="#000">
                  <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.2s" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="40" y="50" width="4" height="10" fill="#000">
                  <animateTransform attributeType="xml"
                    attributeName="transform" type="translate"
                    values="0 0; 0 20; 0 0"
                    begin="0.4s" dur="0.6s" repeatCount="indefinite" />
                </rect>
            </svg>

      <div class="map-preview-3d__loader-text" v-if="loading">Загрузка платежной формы</div>
    </div>

    <div id="ya-payment-form"></div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CardForm',
  data: () => ({
    complete: false,
    loading: false,
  }),
  mounted() {
  },
  methods: {
    closeYaPay() {
      this.$store.commit('hideYaPay');
    },
  },
  computed: {
    isVisible() {
      return this.$store.state.payment.isYaPayVisible;
    },
    webinarPaymentData() {
      return this.$store.state.payment.webinarPaymentData;
    },
    preparedInfo() {
      return this.$store.getters.getMeditationsInfoForCourse;
    },
  },
  watch: {
    isVisible(val) {
      document.querySelector('#ya-payment-form').innerHTML = '';

      if (val) {
        this.loading = true;
        const currentUrl = this.$store.getters.getLocalUrl;

        axios
          .post(`${currentUrl}/api/yandexGetPaymentToken.php`, {
            amount: this.$store.state.payment.priceToPay,
          })
          .then((response) => {
            if (response.data.success) {
              const ids = this.preparedInfo.readyForPayIds.join();
              const packageName = this.$store.state.payment.landingPaymentType;
              let returnURL = `${window.location.href}?yandexPayedID=${response.data.id}&ids=${ids}&packageName=${packageName}`;

              if (this.$route.name === 'WebinarLanding') {
                returnURL = `${window.location.href}?yandexPayedID=${response.data.id}&name=${this.webinarPaymentData.userName}&email=${this.webinarPaymentData.userEmail}&type=webinarPayment`;
              }

              const checkout = new window.YandexCheckout({
                confirmation_token: response.data.confirmation_token,
                return_url: returnURL,
                error_callback() {
                  this.loading = false;
                },
              });

              checkout.render('ya-payment-form').then(() => {
                this.loading = false;
              });
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/common/variables";

.ya-form {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: $white;
  max-width: 640px;
  z-index: 40;
  padding: 15px 10px 10px 10px;
  box-sizing: border-box;
  transition: all 200ms;
  transform: translate(0, 100%);
  overflow: auto;
  left: 0;

    @media only screen and (min-width: 768px) {
        max-width: 768px;
        left: auto;
        margin-left: -24px;
    }

  &__loading {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 300px;
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 20px;

    svg {
      position: relative;
      left: 15px;
      width: 80px;
    }
  }

  &__back {
      width: 24px;
      height: 24px;
      background: $main-blue;
      border-radius: 50%;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 18px;

      &:before {
          width: 100%;
          height: 100%;
          display: flex;
          background: url("/images/close-icon-white.svg") no-repeat 50% 50%;
          content: '';
          background-size: 8px;
      }
  }

  &--visible {
    transform: translate(0);
  }

  #ya-payment-form {
    position: relative;
    z-index: 2;
    margin-top: 60px;
  }
}
</style>
